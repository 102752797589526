<template>
  <cp-filter
    ref="cpFilter"
    name="investorsOnboarding"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="country-code"
          :label="$t('investorsOnboarding.label.filter.country')"
          :options="countriesList"
        />
      </div>

      <div class="col-md mb-4 filterBlock">
        <cp-select
          name="investor-type"
          :label="$t('investorsOnboarding.label.filter.investorType')"
          :options="filterLists.investorType"
        />
      </div>

      <div
        v-if="showOrigin"
        class="col-md mb-4 filterBlock"
      >
        <cp-select
          name="registration-source"
          :label="$t('investorsOnboarding.label.filter.registrationSource')"
          :options="registrationSourceList"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-select
          name="kyc-status"
          :label="$t('investorsOnboarding.label.filter.kycStatus')"
          :options="filterLists.kycStatus"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-select
          name="accredited-status"
          :label="$t('investorsOnboarding.label.filter.accreditedStatus')"
          :options="filterLists.accreditedStatusList"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-date-range
          v-validate="'date_range'"
          name="from-created-at"
          :label="$t('investorsOnboarding.label.filter.createdAt')"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-select
          name="wallet-registration-status"
          :label="$t('investorsOnboarding.label.filter.walletStatus')"
          :options="filterLists.walletRegistrationStatusList"
        />
      </div>

      <div
        v-if="showHasSecuritizeId"
        class="col-md filterBlock"
      >
        <cp-select
          name="has-securitize-id"
          :label="$t('investorsOnboarding.label.filter.hasSecuritizeId')"
          :options="filterLists.hasSecuritizeIdList"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpDateRange from '~/components/common/standalone-components/inputs/cp-date-range';
import CpFilter from '~/components/common/cp-filter';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

import { filterLists } from './options';

export default {
  name: 'OnboardingFilter',
  components: {
    CpSelect,
    CpDateRange,
    CpFilter,
  },
  props: {
    showOrigin: {
      type: Boolean,
      default: false,
    },
    showHasSecuritizeId: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterLists,
      registrationSourceList: [],
    };
  },
  computed: {
    ...mapGetters('global', ['countries']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    isBrokerDealer() {
      return this.authorizationLevel === 'brokerdealer';
    },
    countriesList() {
      return [
        { text: this.$t('common.label.all'), value: null },
        ...(this.countries.map(
          country => ({ text: country.name, value: country.countryCode }),
        )),
      ];
    },
  },
  created() {
    if (!this.isBrokerDealer) {
      this.getPartners(this.$route.params.idIssuer).then(({ data }) => {
        this.registrationSourceList = [
          { i18nKey: 'common.label.all', value: null },
          { i18nKey: 'investorsOnboarding.option.registrationSource.internal', value: 'internal' },
          ...((data || []).map(val => ({ text: val.name, value: `${val.id}__${val.sourceType}` }))),
        ];
      });
    }
  },
  methods: {
    ...mapActions('investors', ['getPartners']),
    updateFilter() {
      this.$emit('updateFilter', this.getActiveFilters());
    },
    getActiveFilters() {
      return filterParseForApi(this.$refs.cpFilter.getDefaultValue);
    },
  },
};
</script>

<style lang="scss">
  .filterBlock {
    margin: 0 2%;
    min-width: 25%;
  }
</style>
