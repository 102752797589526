<template>
  <cp-general-modal
    ref="bulkSummaryModal"
    :title="$t('bulkSummaryModal.title')"
    :message="summaryMessage"
    :scrollable="errors.length > 10"
    @onOk="hide"
  >
    <template slot="modal-header">
      <div class="d-flex justify-content-between w-100 align-content-center">
        <h5 class="modal-title">
          {{ $t('bulkSummaryModal.title') }}
        </h5>
        <cp-button
          v-if="isErrorsAvailable"
          size="sm"
          variant="outline-primary ml-3 ion ion-ios-download"
          @click="exportErrorList"
        >
          {{ $t('bulkSummaryModal.button.download') }}
        </cp-button>
      </div>
    </template>
    <b-table
      v-if="isErrorsAvailable"
      hover
      :items="errors"
      :fields="tableFields"
      head-variant="light"
    >
      <template
        slot="user"
        slot-scope="{ item }"
      >
        <router-link
          :to="{name: 'details', params: {idIssuer, detailsId: item.user.id}}"
          target="_blank"
        >
          {{ item.user.fullName }}
        </router-link>
      </template>
      <template
        slot="error"
        slot-scope="{ item }"
      >
        {{ item.error.name || item.error }}
      </template>
    </b-table>
    <cp-button
      v-if="showDownloadCsv"
      size="sm"
      variant="outline-primary ml-3 ion ion-ios-download"
      @click="downloadCsv"
    >
      {{ $t('bulkSummaryModal.button.downloadCsv') }}
    </cp-button>
  </cp-general-modal>
</template>

<script>
import moment from 'moment';
import FileSaver from 'file-saver';
import { mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpBulkSummaryModal',
  components: {
    CpGeneralModal,
    CpButton,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    successfulActions: {
      type: Number,
      default: 0,
    },
    showDownloadCsv: {
      type: Boolean,
      default: false,
    },
    customSummaryMessageKey: {
      type: String,
      default: undefined,
    },
    csvFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableFields: [
        { key: 'user', label: this.$t('bulkSummaryModal.label.user'), tdClass: 'align-middle' },
        { key: 'error', label: this.$t('bulkSummaryModal.label.error'), tdClass: 'align-middle' },
      ],
    };
  },
  computed: {
    ...mapGetters('issuerList', ['currentIssuer']),
    summaryMessage() {
      if (this.errors.length) {
        return this.$t('bulkSummaryModal.message.error', [this.errors.length]);
      }
      return this.$t(this.customSummaryMessageKey, [this.successfulActions]) || this.$t('bulkSummaryModal.message.success', [this.successfulActions]);
    },
    idIssuer() {
      return this.$route.params.idIssuer;
    },
    isErrorsAvailable() {
      return !!this.errors.length;
    },
  },
  methods: {
    show() {
      this.$refs.bulkSummaryModal.show();
    },
    hide() {
      this.$refs.bulkSummaryModal.hide();
    },
    exportErrorList() {
      const filename = `${this.currentIssuer.name.replace(/\W/g, '_')}_${moment().format('YYYY-MM-DD_h-mm')}_bulk_errors`;
      let csvErrorList = this.$t('bulkSummaryModal.label.csvErrorList');
      this.errors.forEach(({ user, error }) => {
        csvErrorList = `${csvErrorList}${user.id},${user.fullName},${error.name}\n`;
      });
      const blob = new Blob([csvErrorList]);
      FileSaver.saveAs(blob, `${filename}.csv`);
    },
    downloadCsv() {
      this.$emit('onDownloadCsv', this.csvFiles);
    },
  },
};
</script>

<style scoped>

</style>
