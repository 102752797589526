export const accreditedStatus = {
  styles: {
    confirmed: 'success',
    no: 'danger',
    'no-accepted': 'danger',
    rejected: 'danger',
    none: 'default',
    pending: 'warning',
    processing: 'default',
    'not-accredited': 'default',
  },
  labels: {
    confirmed: 'investorsOnboarding.status.accredited.confirmed',
    no: 'investorsOnboarding.status.accredited.no',
    no_accepted: 'investorsOnboarding.status.accredited.noAccepted',
    rejected: 'investorsOnboarding.status.accredited.rejected',
    none: 'investorsOnboarding.status.accredited.none',
    pending: 'investorsOnboarding.status.accredited.pending',
    processing: 'investorsOnboarding.status.accredited.processing',
    'not-accredited': 'investorsOnboarding.status.accredited.notAccredited',
  },
};

export const kycStatus = {
  styles: {
    none: 'default',
    processing: 'warning',
    'manual-review': 'warning',
    'updates-required': 'danger',
    rejected: 'danger',
    verified: 'success',
    'enhanced-verified': 'success',
    expired: 'danger',
  },
  labels: {
    none: 'investorsOnboarding.status.kyc.none',
    processing: 'investorsOnboarding.status.kyc.processing',
    'manual-review': 'investorsOnboarding.status.kyc.manualReview',
    'updates-required': 'investorsOnboarding.status.kyc.updatesRequired',
    rejected: 'investorsOnboarding.status.kyc.rejected',
    verified: 'investorsOnboarding.status.kyc.verified',
    'enhanced-verified': 'investorsOnboarding.status.kyc.enhancedVerified',
    expired: 'investorsOnboarding.status.kyc.expired',
  },
};

export const hasSecuritizeIdStatus = {
  styles: {
    0: 'default',
    1: 'success',
  },
  labels: {
    0: 'investorsOnboarding.status.hasSecuritizeId.none',
    1: 'investorsOnboarding.status.hasSecuritizeId.exists',
  },
};

export const investorTypeList = {
  individual: 'common.category.investorType.individual',
  entity: 'common.category.investorType.entity',
  'fbo-individual': 'common.category.investorType.fboIndividual',
  'fbo-entity': 'common.category.investorType.fboEntity',
  none: 'common.category.investorType.none',
};

export const filterLists = {
  walletRegistrationStatusList: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'investorsOnboarding.option.walletStatus.pending', value: 'pending' },
    { i18nKey: 'investorsOnboarding.option.walletStatus.syncing', value: 'syncing' },
    { i18nKey: 'investorsOnboarding.option.walletStatus.ready', value: 'ready' },
    { i18nKey: 'investorsOnboarding.option.walletStatus.rejected', value: 'rejected' },
    { i18nKey: 'investorsOnboarding.option.walletStatus.cancelled', value: 'cancelled' },
  ],
  investorType: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'common.category.investorType.individual', value: 'individual' },
    { i18nKey: 'common.category.investorType.entity', value: 'entity' },
    { i18nKey: 'common.category.investorType.fboIndividual', value: 'fbo-individual' },
    { i18nKey: 'common.category.investorType.fboEntity', value: 'fbo-entity' },
    { i18nKey: 'common.category.investorType.none', value: 'none' },
  ],
  kycStatus: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'investorsOnboarding.option.kycStatus.none', value: 'none' },
    { i18nKey: 'investorsOnboarding.option.kycStatus.processing', value: 'processing' },
    { i18nKey: 'investorsOnboarding.option.kycStatus.manualReview', value: 'manual-review' },
    { i18nKey: 'investorsOnboarding.option.kycStatus.updatesRequired', value: 'updates-required' },
    { i18nKey: 'investorsOnboarding.option.kycStatus.verified', value: 'verified' },
    { i18nKey: 'investorsOnboarding.option.kycStatus.rejected', value: 'rejected' },
    { i18nKey: 'investorsOnboarding.option.kycStatus.expired', value: 'expired' },
  ],
  accreditedStatusList: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.no', value: 'no' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.noAccepted', value: 'no-accepted' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.pending', value: 'pending' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.confirmed', value: 'confirmed' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.rejected', value: 'rejected' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.none', value: 'none' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.notAccredited', value: 'not-accredited' },
    { i18nKey: 'investorsOnboarding.option.accreditedStatusList.processing', value: 'processing' },
  ],
  hasSecuritizeIdList: [
    { i18nKey: 'common.label.all', value: null },
    { i18nKey: 'investorsOnboarding.option.hasSecuritizeIdList.none', value: false },
    { i18nKey: 'investorsOnboarding.option.hasSecuritizeIdList.exists', value: true },
  ],
};

export const tableFields = [
  { key: 'checkbox', label: ' ', tdClass: 'text-nowrap align-middle' },
  {
    key: 'name', i18nKey: 'investorsOnboarding.label.table.investorName', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'countryCode', i18nKey: 'investorsOnboarding.label.table.country', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'investorType', i18nKey: 'investorsOnboarding.label.table.investorType', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'registrationSource', i18nKey: 'investorsOnboarding.label.table.registrationSource', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'hasSecuritizeId', i18nKey: 'investorsOnboarding.label.table.hasSecuritizeId', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'kycStatus', i18nKey: 'investorsOnboarding.label.table.kycStatus', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'riskLevel', i18nKey: 'investorsOnboarding.label.table.riskLevel', sortable: false, tdClass: 'align-middle',
  },
  {
    key: 'accreditedStatus', i18nKey: 'investorsOnboarding.label.table.accreditedStatus', sortable: true, tdClass: 'align-middle',
  },
  { key: 'labels', i18nKey: 'investorsOnboarding.label.table.labels', tdClass: 'align-middle' },
  {
    key: 'createdAt', i18nKey: 'investorsOnboarding.label.table.createdAt', sortable: true, tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
];
