<template>
  <cp-general-modal
    ref="sendEmailModal"
    :title="$t('investorDetails.sendInviteModal.title')"
    size="lg"
    title-icon="ion ion-ios-mail"
    :ok-disabled="disabled"
    @onOk="sendInvite"
  >
    <cp-input
      name="emailContent"
      :placeholder="$t('investorDetails.sendInviteModal.input.emailContent.placeholder')"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'SendInviteModal',
  components: {
    CpGeneralModal,
    CpInput,
  },
  props: {
    isBulk: {
      type: Boolean,
      default: false,
    },
    userIds: {
      type: Array,
      default: () => [],
    },
    excludedUserIds: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    ...mapActions('investors', ['sendInviteEmail', 'bulkInviteEmail']),
    show() {
      this.$refs.sendEmailModal.show();
    },
    sendInvite({ emailContent }) {
      this.disabled = true;
      const issuerId = this.$route.params.idIssuer;
      if (this.isBulk) {
        const body = {
          userIds: this.userIds.length ? this.userIds : null,
          exceptInvestorsIds: this.excludedUserIds.length ? this.excludedUserIds : null,
          emailContent,
          ...this.filters,
        };
        this.bulkInviteEmail({ issuerId, body })
          .then((res) => {
            this.$emit('onInviteSended', res);
          })
          .finally(() => {
            this.disabled = false;
            this.$refs.sendEmailModal.hide();
          });
      } else {
        this.sendInviteEmail({
          issuerId,
          userId: this.$route.params.detailsId,
          body: {
            emailContent,
          },
        }).finally(() => {
          this.disabled = false;
          this.$refs.sendEmailModal.hide();
        });
      }
    },
  },
};
</script>
