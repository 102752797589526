import { render, staticRenderFns } from "./bulk-summary-modal.vue?vue&type=template&id=4ed40446&scoped=true&"
import script from "./bulk-summary-modal.vue?vue&type=script&lang=js&"
export * from "./bulk-summary-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed40446",
  null
  
)

export default component.exports