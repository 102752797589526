var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-table',{ref:"cpTable",attrs:{"default-sort-by":"createdAt","default-sort-direction":"desc","checkbox":"","mass-selectable":"","filters":_vm.params,"get-data-action":"investors/getInvestorsList","url-params":_vm.urlParams,"fields":_vm.tableFields,"data-parser":_vm.listCorrection,"search-value":_vm.searchQueryParam,"table-title":_vm.$t('investorsOnboarding.title')},on:{"onSelect":function($event){return _vm.onSelect($event)},"onSelectAll":function($event){return _vm.onSelectAll($event)},"onSort":function($event){return _vm.$emit('onSort', $event)},"tableDataUpdated":_vm.onTableDataUpdated,"onMassSelect":_vm.onMassSelect},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [(!rowData.item.isAggregatedBrokerDealerGroup)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('onViewElem', rowData.item, $event)}}},[_vm._v(" "+_vm._s(_vm.getFullName(rowData.item))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.getFullName(rowData.item))+" ")])]}},{key:"countryCode",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-country-name',{attrs:{"country-code":rowData.item.countryCode,"locale":_vm.locale}})]}},{key:"investorType",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.investorTypeValue)+" ")]}},{key:"registrationSource",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(rowData.item.registrationSourceValue)+" ")]}},{key:"hasSecuritizeId",fn:function(ref){
var rowData = ref.rowData;
return (_vm.showHasSecuritizeId)?[_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":rowData.item.hasSecuritizeIdStyle}},[_vm._v(" "+_vm._s(rowData.item.hasSecuritizeIdValue)+" ")])]:undefined}},{key:"kycStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":rowData.item.kycStatusStyle}},[_vm._v(" "+_vm._s(rowData.item.kycStatusValue)+" ")])]}},{key:"accreditedStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":rowData.item.accreditedStatusStyle}},[_vm._v(" "+_vm._s(rowData.item.accreditedStatusValue)+" ")])]}},{key:"labels",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isLabels(rowData.item))?_c('div',[_vm._l((_vm.getLabelsList(rowData.item)),function(label){return _c('span',{key:label},[(label)?_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(label)+" ")]):_vm._e()],1)}),(rowData.item.labels.length > 3)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.getLabelsTitle(rowData.item)}},[_vm._v(" + "+_vm._s(rowData.item.labels.length - 3)+" ")]):_vm._e()],2):_vm._e()]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.createdAt))+" ")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[(!rowData.item.isAggregatedBrokerDealerGroup)?_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat"},on:{"click":function($event){return _vm.$emit('onViewElem', rowData.item, $event)}}},[_c('i',{staticClass:"ion ion-ios-eye"})]):_vm._e(),(!rowData.item.isAggregatedBrokerDealerGroup)?_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat ml-3"},on:{"click":function($event){return _vm.$emit('onRemoveElem', rowData.item, $event)}}},[_c('i',{staticClass:"ion ion-ios-trash"})]):_vm._e()],1)]}}],null,true)},[_c('template',{slot:"tableHeader"},[_c('span',{staticClass:"headers-wrapper"},[_c('div',{staticClass:"batch-actions"},[_c('cp-batch-actions',{attrs:{"disabled":_vm.isEmptyExportList},on:{"onExportList":function($event){return _vm.$emit('onExportList')},"onEditLabels":function($event){return _vm.$emit('onEditLabels')},"onShowLabels":function($event){return _vm.$emit('onShowLabels')}}})],1),_c('div',{staticClass:"input-group"},[_c('cp-select',{attrs:{"input-class":"","options":_vm.operations},model:{value:(_vm.selectedOperation),callback:function ($$v) {_vm.selectedOperation=$$v},expression:"selectedOperation"}}),_c('span',{staticClass:"input-group-append"},[_c('cp-button',{attrs:{"disabled":_vm.selectedInvestorsCount === 0,"size":"ms","variant":"primary","is-loading":_vm.isBulkLoading},on:{"click":_vm.handleBulkOperationClick}},[_vm._v(" "+_vm._s(_vm.$t('investorsOnboarding.label.run', [_vm.selectedInvestorsCount]))+" ")])],1)],1)]),_c('cp-send-invite-email-modal',{ref:"invite-modal",attrs:{"is-bulk":"","user-ids":_vm.userIds,"excluded-user-ids":_vm.excludedUserIds,"filters":_vm.bulkActionFilters},on:{"onInviteSended":_vm.showBulkSummaryModal}}),_c('cp-bulk-summary-modal',_vm._b({ref:"bulk-summary"},'cp-bulk-summary-modal',_vm.bulkSummary,false))],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }