<template>
  <div>
    <div v-show="isReady">
      <cp-investors-head
        :title="$t('investorsOnboarding.title')"
        :country-codes="getCountryCodes"
        @handleImportInvestors="importInvestors"
      />
      <cp-onboarding-filter
        ref="onboardingFilter"
        :show-origin="showOriginColumn"
        :show-has-securitize-id="showHasSecuritizeIdColumn"
        @updateFilter="updateFilter"
      />
      <cp-onboarding-table
        v-if="$refs.onboardingFilter"
        :ref="tableRef"
        :filters="tableFilters"
        :show-origin="showOriginColumn"
        :show-has-securitize-id="showHasSecuritizeIdColumn"
        @onViewElem="toViewPage"
        @onRemoveElem="handlerRemoveInvestor"
        @onExportList="exportInvestorsList"
        @onSelectInvestor="selectInvestor"
        @onSort="saveSort"
        @onShowLabels="showFilterLabelsModal"
        @onEditLabels="editInvestorsLabels"
        @onSelectAll="selectAllInvestors"
        @onMassSelect="handleMassSelect"
      />
    </div>
    <div
      v-show="!isReady"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('investorsOnboarding.loading') }}
      </div>
    </div>
    <cp-labels-modal
      ref="CpLabelsModal"
      :modal-id="modalId"
      :error-field="errorField"
      :selected-invest="isSelectable"
      :hide-create-label="hideCreateLabel"
      :result-filter-labels="resultFilterLabels"
      :predefined-labels="resultFilterPredefinedLabels"
      @onApplyEditLabels="handleEditLabels"
      @onApplyFilterLabels="handleFilterLabels"
      @onHide="errorField = false"
      @onCreateLabel="handleCreateLabel"
      @searchLabel="handleSearchLabel"
      @onDeleteLabel="handleDeleteLabel"
    />
    <cp-remove-investor
      ref="removeInvestModal"
      :item="deleteItem"
      @deleteIsApproved="deleteIsApproved"
    />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';

import CpLabelsModal from '~/pages/_idIssuer/investors/modals/labelModal';
import CpOnboardingTable from './components/table';
import CpOnboardingFilter from './components/filter';
import CpInvestorsHead from '~/pages/_idIssuer/investors/head-investors';
import CpRemoveInvestor from '~/pages/_idIssuer/investors/remove-investor';

import GeneralActionsOfInvestorPages from '~/mixins/general-actions-of-investor-pages';
import routeNames from '~/utilities/routes';

export default {
  name: 'InvestorsOnboarding',
  metaInfo: {
    title: 'Onboarding',
  },
  components: {
    CpOnboardingTable,
    CpInvestorsHead,
    CpLabelsModal,
    CpOnboardingFilter,
    CpRemoveInvestor,
  },
  mixins: [GeneralActionsOfInvestorPages],
  data() {
    return {
      investorsType: {
        issuerId: this.$route.params.idIssuer,
        view: 'onboarding',
      },
      tableRef: 'onboardingTable',
      filterRef: 'onboardingFilter',
    };
  },
  computed: {
    ...mapGetters('investors', [
      'getInvestorById',
      'getSorting',
      'labelsList',
    ]),
    ...mapGetters('issuerList', ['currentIssuer']),
    ...mapGetters('global', ['countries']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
    ...mapState('issuersInfo', ['currentToken']),
    ...mapState('predefinedLabels', ['predefinedLabels']),
    showOriginColumn() {
      return ['admin', 'megaadmin', 'superadmin'].includes(this.authorizationLevel);
    },
    showHasSecuritizeIdColumn() {
      return ['megaadmin', 'superadmin', 'brokerdealer', 'admin'].includes(this.authorizationLevel);
    },
  },
  created() {
    this.getSystemPredefinedLabels();
  },
  methods: {
    ...mapActions('investors', [
      'exportList',
      'getPartners',
      'getIssuerLabels',
      'labelsBatchOperation',
      'deleteInvestorByUserId',
      'deleteIssuerLabels',
    ]),
    ...mapActions('predefinedLabels', ['getSystemPredefinedLabels']),
    ...mapMutations('investors', ['SET_INVESTOR_SORTING', 'SET_LABELS_LIST', 'CREATE_NEW_LABEL']),
    toViewPage({ id: idOnboarding }, $event) {
      const { params: { idIssuer }, query: { search } } = this.$route;
      const tokenId = this.currentToken && this.currentToken.id;
      const routeData = this.$router.resolve(routeNames.viewDetails(idIssuer, tokenId, idOnboarding, 'onboarding', search));
      if ($event.metaKey) {
        window.open(routeData.href);
      } else {
        this.$router.push(routeData.href);
      }
    },
  },
};
</script>
